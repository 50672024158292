


.payDashboardPie{
    width: 70%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    max-height: 98%;
    min-height: 98%;
    align-self: center;
    /*margin-left: 25px;
    margin-top: 30px;*/
}
.payrollDashTh1{
    height: 15px;
    max-height: 15px;
    background-color: #1890FF;
    font-size: 11px;
    color:white
}
.payrollDashTh2{
    height: 20px;
    max-height: 20px;
    background-color: #203764;
    font-size: 10px;
    font-weight: bold;
    color:white;
    align-items: center;
    padding-top: 5px;
}
.payrollDashTd1{
    height: 15px;
    max-height: 15px;
    font-size: 11px;
    text-align: right;
}
.pushLeft{
    text-align: left;
}
.pushRight{
    text-align: right;
}
.pushCenter{
    text-align: center;
}
.tripper{
    background-color: #EFF6FB;
}
.notClaimed{
    color:red;
}
.bb1{
    min-height: 200px;
    max-height: 200px;
}
.jj{
    min-height: 100%;
    max-height: 100%;
}
.headerTable{
    font-weight: bold;
    font-size: 12px;
    color: #203764;

}
.headerTableContainer{
    padding-top: 10px;
    padding-left: 10px;
}